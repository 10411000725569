(function ($) {
  $("[data-toggle=popover]").popover();

  $('a[href="#collapseOne"]').on("click", function () {
    $(this).attr("href", "#");
    $(window).trigger("resize");
  });

  if (typeof $.fn.slick !== "undefined") {
    const $offerSlider = $(".offers-slider");
    const $collapseOne = $("#collapseOne");
    const $bottomOffers = $("#bottom-offers");
    $bottomOffers.on("shown.bs.collapse", function () {
      $(window).trigger("scroll");
    });
    if ($offerSlider.length) {
      if ($collapseOne.length) {
        $offerSlider.css("height", "201px");
        $bottomOffers.css("bottom", "").css("opacity", "1");
      }
      $offerSlider.slick({
        infinite: true,
        // variableWidth: true,
        slidesToShow: 4,
        prevArrow:
          '<button class="flickity-button flickity-prev-next-button previous" type="button"' +
          ' aria-label="Previous"><svg class="flickity-button-icon" viewBox="0 0 100 100"><path d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z" class="arrow"></path></svg></button>',
        nextArrow:
          '<button class="flickity-button flickity-prev-next-button next" type="button" aria-label="Next"><svg class="flickity-button-icon" viewBox="0 0 100 100"><path d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z" class="arrow" transform="translate(100, 100) rotate(180) "></path></svg></button>',
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      });
    }

    $(".content-slider[data-slick]").each(function () {
      $(this).slick({
        dots: true,
        infinite: true,
        prevArrow:
          '<button class="flickity-button flickity-prev-next-button previous" type="button" aria-label="Previous"><svg class="flickity-button-icon" viewBox="0 0 100 100"><path d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z" class="arrow"></path></svg></button>',
        nextArrow:
          '<button class="flickity-button flickity-prev-next-button next" type="button" aria-label="Next"><svg class="flickity-button-icon" viewBox="0 0 100 100"><path d="M 10,50 L 60,100 L 70,90 L 30,50  L 70,10 L 60,0 Z" class="arrow" transform="translate(100, 100) rotate(180) "></path></svg></button>',
        autoplay: true,
        autoplaySpeed: 5000,
      });
    });
  }

  // getCartQty();

  function lazyLoadImagesCallback(entries, observer) {
    for (var i = 0; i < entries.length; i++) {
      var entry = entries[i];
      if (entry.isIntersecting) {
        var img = entry.target;
        copyImgAttributesFromData(img);
        observer.unobserve(img);
      }
    }
  }

  function observeLazyLoadImages() {
    var options = {
      // If the image is within half a viewport of the screen, load it
      rootMargin: "50%",
    };

    var observer = new IntersectionObserver(lazyLoadImagesCallback, options);

    var imgs = document.querySelectorAll(".lazy");
    for (var i = 0; i < imgs.length; i++) {
      var img = imgs[i];
      observer.observe(img);
    }
  }

  function copyImgAttributesFromData(img) {
    var src = img.getAttribute("data-src");
    var srcset = img.getAttribute("data-srcset");
    var sizes = img.getAttribute("data-sizes");
    if (src) {
      img.setAttribute("src", src);
    }
    if (srcset) {
      img.setAttribute("srcset", srcset);
    }
    if (sizes) {
      img.setAttribute("sizes", sizes);
    }
  }

  function loadAllImages() {
    var imgs = document.querySelectorAll(".lazy");
    for (var i = 0; i < imgs.length; i++) {
      var img = imgs[i];
      copyImgAttributesFromData(img);
    }
  }

  function loadImages() {
    if ("IntersectionObserver" in window) {
      observeLazyLoadImages();
    } else {
      loadAllImages();
    }
  }

  if (document.readyState !== "loading") {
    loadImages();
  } else {
    document.addEventListener("DOMContentLoaded", function () {
      loadImages();
    });
  }

  // const body = document.querySelector('body')
  // const html = document.querySelector('html')

  var navSlideT;

  function closeDrawer() {
    if ($("body").hasClass("drawer-open")) {
      $("body").removeClass("drawer-open");
      $(".LPMcontainer").css("z-index", "107158");
      $("#attentive_overlay").css("z-index", "2147483647");
      $("#bottom-offers").css("z-index", "200");
      $("html").css("overflow", "auto");

      $("#drawer .menu:visible").trigger("closed.gw.drawer");
    }
  }

  window.addEventListener("keydown", function (event) {
    if (event.keyCode == 27 && event.type == "keydown") {
      closeDrawer();
    }
  });

  $("#bodyWrapper").prepend(
    $('<div class="drawer-bg-overlay"></div>').on("click", function (e) {
      e.preventDefault();
      closeDrawer();
    })
  );
  $('[data-toggle="drawer-menu"]').on("click", function (e) {
    e.preventDefault();
    var $target = $($(e.currentTarget).data("target"));
    $("body").addClass("drawer-open");
    $("html").css("overflow", "hidden");
    $("#drawer .menu").removeClass("active");
    $("#bottom-offers, .LPMcontainer, #attentive_overlay").css(
      "z-index",
      "auto"
    );

    $target.addClass("active").trigger("opened.gw.drawer");
  });

  $("#main-menu")
    /*.on('touchstart','a',function(e){
        var $link = $(e.currentTarget)
          , $parent = $link.parent();

        if ($parent.has('ul').length > 0) {
          e.preventDefault();
          $parent.parent().find('> li').removeClass('active');
          $parent.addClass('active');
        }
      })*/
    .on("click", "nav > ul a[data-toggle]", function (e) {
      if ($(window).width() >= 992) return true;

      e.preventDefault();
      var $link = $(e.target),
        $listItems = $link.parents("li"),
        $nav = $link.parents("nav"),
        clearNavSlide = function () {
          $nav.removeClass("slide");
          $nav.find(".prev").removeClass("prev");
        };

      clearTimeout(navSlideT);

      $listItems.removeClass("active parent");
      $($listItems[1]).addClass("prev");

      if ($link.hasClass("back")) {
        if ($listItems.length >= 3)
          $($listItems[2]).find("> a").trigger("click");
        else {
          $nav.addClass("slide").css("left", "0");
          navSlideT = setTimeout(clearNavSlide, 300);
        }
      } else {
        $nav.addClass("slide").css("left", "-" + 100 * $listItems.length + "%");
        $listItems.first().addClass("active");
        $($listItems.slice(1)).addClass("parent");
        navSlideT = setTimeout(clearNavSlide, 300);
      }
    })
    .on("closed.gw.drawer", function (e) {
      var $nav = $(e.delegateTarget).find("> nav");

      clearTimeout(navSlideT);
      navSlideT = setTimeout(function () {
        $nav.find(".active, .prev, .parent").removeClass("active prev parent");
        $nav.addClass("slide").css("left", "0");
      }, 300);
    });

  $(window).on("resize", function () {
    $("#bodyWrapper .drawer-bg-overlay").trigger("click");
  });
  let $locationEls = $("[data-location-id]");
  if ($locationEls.length > 0) {
    $locationEls.each(function () {
      var $el = $(this),
        locationId = $el.data("locationId");

      if (locationId) {
        $.ajax("/api/locations/" + locationId).done(function (data) {
          if (data.hasOwnProperty("response")) {
            var location = data.response,
              currentDate = new Date(),
              closed = false,
              hours,
              currentHoursText;

            if (location.hasOwnProperty("hours")) {
              if (
                location.hours.hasOwnProperty("special") &&
                Array.isArray(location.hours.special)
              ) {
                for (var i = 0; i < location.hours.special.length; i++) {
                  var specialEntry = location.hours.special[i],
                    specialEntryDate = new Date();

                  specialEntryDate.setFullYear(
                    Number(specialEntry.date.substring(0, 4))
                  );
                  specialEntryDate.setMonth(
                    Number(specialEntry.date.substring(5, 7)) - 1,
                    Number(specialEntry.date.substring(8, 10))
                  );

                  // The special entry date is today
                  if (
                    currentDate.getFullYear() ==
                      specialEntryDate.getFullYear() &&
                    currentDate.getMonth() == specialEntryDate.getMonth() &&
                    currentDate.getDate() == specialEntryDate.getDate()
                  ) {
                    hours = specialEntry.open;
                    break;
                  }
                }
              }

              if (!hours) {
                var entry = location.hours[currentDate.getDay()];

                if (entry.hasOwnProperty("closed")) closed = true;
                else if (entry.hasOwnProperty("open")) hours = entry.open;
              }

              if (closed) currentHoursText = "Closed today";
              else if (hours) {
                var openDate = new Date(),
                  closeDate = new Date(),
                  timeParts;

                timeParts = hours[0].split(":");
                openDate.setHours(timeParts[0], timeParts[1], timeParts[2]);

                timeParts = hours[1].split(":");
                closeDate.setHours(timeParts[0], timeParts[1], timeParts[2]);

                // Not open yet today
                if (currentDate < openDate) {
                  currentHoursText = "Opens at ";

                  currentHoursText += (openDate.getHours() + 24) % 12 || 12;
                  if (openDate.getMinutes() > 0)
                    currentHoursText +=
                      ":" + ("0" + openDate.getMinutes()).slice(-2);
                  currentHoursText += openDate.getHours() > 12 ? "pm" : "am";
                }

                // Currently open
                else if (currentDate < closeDate) {
                  currentHoursText = "Open until ";

                  currentHoursText += (closeDate.getHours() + 24) % 12 || 12;
                  if (closeDate.getMinutes() > 0)
                    currentHoursText +=
                      ":" + ("0" + closeDate.getMinutes()).slice(-2);
                  currentHoursText += closeDate.getHours() > 12 ? "pm" : "am";
                }

                // Closed for today
                else {
                  var entry = location.hours[currentDate.getDay()];

                  if (entry.hasOwnProperty("closed")) closed = true;
                  else if (entry.hasOwnProperty("open")) hours = entry.open;

                  if (closed) currentHoursText = "Closed tomorrow";
                  else {
                    currentHoursText = "Opens at ";

                    timeParts = hours[0].split(":");
                    openDate.setHours(timeParts[0], timeParts[1], timeParts[2]);

                    currentHoursText += (openDate.getHours() + 24) % 12 || 12;
                    if (openDate.getMinutes() > 0)
                      currentHoursText +=
                        ":" + ("0" + openDate.getMinutes()).slice(-2);
                    currentHoursText += openDate.getHours() > 12 ? "pm" : "am";
                  }
                }
              }

              $el.find(".location-current-hours").text(currentHoursText);
            }

            $el.find(".location-name").text(location.name);
            $el.find(".location-link").attr("href", location.url);

            if (location.hasOwnProperty("calendly_url")) {
              $el
                .find(".location-appt-btn")
                .data(
                  "calendly_url",
                  location.calendly_url + "/in-store-consultation"
                )
                .click(function (e) {
                  var $btn = $(e.currentTarget),
                    url = $btn.data("calendly_url");

                  if (url) {
                    e.preventDefault();
                    Calendly.initPopupWidget({
                      url: url,
                    });
                  }
                });
            }
          }
        });
      }
    });
  }

  var isClick = false;
  var options = "#main-menu li a, a, button, .toggle, .toggle2";
  $("#main-menu li a, a, button, .toggle, .toggle2").on(
    "focusin",
    function (e) {
      if ($(window).width() > 992) {
        if (isClick === false) {
          $(".focused").removeClass("focused");
          $(e.currentTarget).parents("#main-menu li").addClass("focused");
          $("#main-menu .active:not(.focused) .toggle").attr(
            "aria-expanded",
            "false"
          );
          $("#main-menu .active:not(.focused) .toggle2").attr(
            "aria-expanded",
            "false"
          );
          $("#main-menu .active:not(.focused)").removeClass("active");
        } else {
          $(".focused").removeClass("focused");
          isClick = false;
        }
      }
    }
  );

  $("#main-menu a").on("mousedown", function () {
    isClick = true;
  });

  $(".toggle").on("click keydown", function (e) {
    if ($(window).width() > 992) {
      if (e.keyCode === 13 || e.type === "click") {
        e.stopImmediatePropagation();
        e.preventDefault();
        var $parent = $(this).parent();
        if (
          !$parent.hasClass("active") &&
          !$(this).next(".first-level").is(":visible")
        ) {
          $parent.addClass("active");
          $(this).attr("aria-expanded", "true");
        } else {
          $parent.removeClass("active");
          $(this).attr("aria-expanded", "false");
        }
      }
    }
  });
  $(".toggle2").on("click keydown", function (e) {
    if (e.keyCode === 13 || e.type === "click") {
      e.stopImmediatePropagation();
      e.preventDefault();
      var $parent = $(this).parent();
      var $parentLI = $parent.parent();
      if (!$(this).next(".second-level").is(":visible")) {
        $parent.addClass("active");
        $(this).attr("aria-expanded", "true");
      } else {
        $parent.removeClass("active");
        $(this).attr("aria-expanded", "false");
      }
    }
  });

  function addNavClass() {
    if ($(window).width() < 992) {
      // Insert needed for mobile
      // $("body").addClass("mobile");
      //TODO: negitive tab index on toggle and toggle2 on mobile
      // $("body").removeClass("desktop");
    } else {
      // Insert needed for desktop
      // $("body").addClass("desktop");
      // $("body").removeClass("mobile");
    }
  }

  addNavClass();
  $(window).resize(addNavClass);

  $("#skip").on("click", function (e) {
    e.preventDefault();
    var $main = $("#main");
    $main.attr("tabindex", "-1");
    $main.focus();
    $main.removeAttr("tabindex");
  });

  $("#main-menu-btn").on("click", function () {
    $("#main-menu > nav > ul > li:first-of-type > a:first-of-type").focus();
  });
})(jQuery);
