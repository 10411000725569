import axios from "axios";

function cartCount(obj) {
  if (!obj.items) return 0;
  return obj.items.length === 1
    ? obj.items[0].quantity
    : obj.items.reduce((a, b) => a + b.quantity, 0);
}

function cartLabel(cartLength) {
  if (cartLength === 0) {
    return `No items in cart`;
  }
  return cartLength === 1 ? `1 item in cart` : `${cartLength} items in cart`;
}

export const getCartQty = () => {
  let cTotal;
  let cLabel;
  const $cartBadge = document.querySelector("#cart .badge");
  const $cart = document.querySelector("#cart");
  if (sessionStorage.getItem("cartQty")) {
    cTotal = cartCount(JSON.parse(sessionStorage.getItem("cartQty")));
    // if (cTotal > 0) {
    if ($cartBadge) {
      $cartBadge.textContent = cTotal;
    }
    cLabel = cartLabel(cTotal);
    if ($cart) {
      $cart.setAttribute("aria-label", cLabel);
    }
    // }
  }
  axios.get("/shop/cart.json").then(({ data }) => {
    let cart;
    if (!sessionStorage.getItem("cartQty")) {
      sessionStorage.setItem("cartQty", JSON.stringify(data));
      cart = data;
    } else {
      cart = JSON.parse(sessionStorage.getItem("cartQty"));
      if (cartCount(cart) !== cartCount(data)) {
        sessionStorage.setItem("cartQty", JSON.stringify(data));
        cart = data;
      }
    }
    cTotal = cartCount(cart);
    cLabel = cartLabel(cTotal);
    // if (cTotal > 0) {
    // const $cartBadge = document.querySelector('#cart .badge')
    if ($cartBadge) {
      $cartBadge.textContent = cTotal;
    }
    // document.querySelector('#cart .badge')?.textContent = cTotal
    // const $cart = document.querySelector('#cart')
    if ($cart) {
      $cart.setAttribute("aria-label", cLabel);
    }
    // } else {
    //   document.querySelector('#cart .badge').textContent = ""
    //   document.querySelector('#cart').setAttribute('aria-label', cLabel)
    // }
  });
};
