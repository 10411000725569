(function ($) {
  function getTimeRemaining(endtime) {
    var t = Date.parse(endtime) - Date.parse(new Date());
    var seconds = Math.floor((t / 1000) % 60);
    var minutes = Math.floor((t / 1000 / 60) % 60);
    var hours = Math.floor((t / (1000 * 60 * 60)) % 24);
    var days = Math.floor(t / (1000 * 60 * 60 * 24));
    return {
      total: t,
      days: days,
      hours: hours,
      minutes: minutes,
      seconds: seconds,
    };
  }

  $(function () {
    var $clock = $(".global-banner-countdown .time-remaining");
    var timeinterval = setInterval(function () {
      var t = getTimeRemaining("February 7 2016 18:35:00");
      $clock.text(
        ("0" + Number(t.days)).slice(-2) +
          ":" +
          ("0" + Number(t.hours)).slice(-2) +
          ":" +
          ("0" + Number(t.minutes)).slice(-2) +
          ":" +
          ("0" + Number(t.seconds)).slice(-2)
      );
      if (t.total <= 0) {
        clearInterval(timeinterval);
        $clock.parent().remove();
      }
    }, 1000);

    var $affixNavbar = $(".body-header .navbar-products");

    if (typeof $.fn.affix === "function") {
      $affixNavbar
        .affix({
          offset: {
            top: function () {
              var headerPromoHeight = $(".promo-bar-expanded #header-promo-bar")
                .length
                ? $("#header-promo-bar").outerHeight()
                : 0;
              return (
                $(".body-header").outerHeight(true) -
                headerPromoHeight -
                $affixNavbar.outerHeight(true)
              );
            },
            bottom: function () {
              var headerPromoHeight = $(".promo-bar-expanded #header-promo-bar")
                .length
                ? $("#header-promo-bar").outerHeight()
                : 0;
              return $(".body-header").outerHeight(true) - headerPromoHeight;
            },
          },
        })
        .on("affix.bs.affix", function () {
          if (!$(".navbar-toggle").is(":visible")) {
            var headerPromoHeight = $(".promo-bar-expanded #header-promo-bar")
              .length
              ? $("#header-promo-bar").outerHeight()
              : 0;
            $affixNavbar.css("top", headerPromoHeight);
            $(".body-header").css({
              marginBottom: function () {
                return $affixNavbar.outerHeight(true);
              },
            });
          }
        })
        .on("affix-top.bs.affix affix-bottom.bs.affix", function () {
          $affixNavbar.css("top", "auto");
          if (!$(".navbar-toggle").is(":visible")) {
            $(".body-header").css({
              marginBottom: 0,
            });
          }
        });
    }

    $(".navbar-products .navbar-nav > li").hoverIntent({
      sensitivity: 7,
      interval: 100,
      timeout: 200,
      over: function (e) {
        var $item = $(this),
          $submenu = $item.find(".submenu");

        if (
          $(".navbar-header .navbar-toggle").is(":hidden") &&
          $submenu.length
        ) {
          $submenu.show();
        }
      },
      out: function () {
        var $item = $(this),
          $submenu = $item.find(".submenu");

        $submenu.hide();
      },
    });

    var $carousels = $(".carousel"),
      $contentGrids = $(".content-grid");

    if ($carousels.length) {
      $carousels.each(function () {
        var $carousel = $(this),
          $items = $carousel.find(".carousel-inner .item"),
          $indicators = $carousel.find(".carousel-indicators li");

        $($items[0]).addClass("active");
        $($indicators[0]).addClass("active");
      });
    }

    // if ($contentGrids.length) {
    //   $contentGrids.each(function () {
    //     var $contentGrid = $(this),
    //       $items = $contentGrid.find(".row > *"),
    //       columnCount = 0;
    //
    //     $items.each(function () {
    //       var $item = $(this),
    //         classNames = $item.attr("class").split(" "),
    //         columnSpan = 0;
    //
    //       for (var i = 0; i < classNames.length; i++) {
    //         if (classNames[i].search(/col-sm-/i) === 0)
    //           columnSpan = new Number(classNames[i].substr(7));
    //       }
    //
    //       if (columnCount + columnSpan > 12) {
    //         $item.before($('<div class="clearfix"></div>'));
    //         columnCount = columnSpan;
    //       } else {
    //         columnCount += columnSpan;
    //       }
    //     });
    //   });
    // }

    function cartCount(obj) {
      if (!obj.items) return 0;
      return obj.items.length === 1
        ? obj.items[0].quantity
        : obj.items.reduce((a, b) => a + b.quantity, 0);
    }

    function cartLabel(cartLength) {
      if (cartLength === 0) {
        return "No items in cart";
      }
      return cartLength === 1
        ? "1 item in cart"
        : `${cartLength} items in cart`;
    }

    function getCustomCartItems(target) {
      var selectedStrings = [];
      var selected = $(
        ".product-selector .product-select > .dropdown .option.selected"
      );

      selected.each(function () {
        var text = $(this).find(".name").text();
        selectedStrings.push(text);
      });

      console.log("custom cart items", selectedStrings);
      $.ajax({
        url: "/shop/cart.json",
        methods: "GET",
        dataType: "json",
        cache: false,
      }).done(function (json) {
        if (json.id && json.items) {
          json.items.forEach(function (item) {
            if (item.target_id === target.data("product-id")) {
              if (
                item.hasOwnProperty("options") &&
                item.options.hasOwnProperty("public_custom_options")
              ) {
                var stringCount = 0;
                var activeItem = false;
                var alternativeItem = false;
                selectedStrings.forEach(function (selectedString) {
                  if (
                    item.options.public_custom_options.indexOf(
                      selectedString
                    ) !== -1
                  ) {
                    stringCount++;
                    activeItem = true;
                  } else if (item.target_id === target.data("product-id")) {
                    stringCount++;
                    alternativeItem = true;
                  }
                });

                if (stringCount === selectedStrings.length) {
                  if (activeItem) {
                    target
                      .button("complete")
                      .text(target.data("complete-text"))
                      .prepend("&nbsp;")
                      .removeClass("btn-gw2")
                      .addClass("btn-gw1")
                      .attr("href", "/shop/cart")
                      .parents(".actions")
                      .find(".extras")
                      .remove();
                    // getCartQty()
                  }
                  if (alternativeItem) {
                    if (target.data("stocked")) {
                      target
                        .button("update")
                        .removeClass("btn-gw1")
                        .addClass("btn-gw2")
                        .attr("href", target.attr("data-href"))
                        .removeAttr("data-toggle")
                        .removeAttr("data-target");
                    } else {
                      target
                        .button("update")
                        .removeClass("btn-gw1")
                        .addClass("btn-gw2")
                        .attr("data-toggle", "modal")
                        .attr("data-target", "#specialOrderModal")
                        .removeAttr("href");
                    }
                  }
                  getCartQty();
                }
              }
            }
          });
        }
      });
    }

    function getCartQty(json) {
      let cartTotal;
      let _cartLabel;
      let cart;
      if (!sessionStorage.getItem("cartQty")) {
        sessionStorage.setItem("cartQty", JSON.stringify(json));
        cart = json;
      } else {
        cart = JSON.parse(sessionStorage.getItem("cartQty"));
        if (typeof json === "undefined") json = cart;
        if (cartCount(cart) !== cartCount(json)) {
          sessionStorage.setItem("cartQty", JSON.stringify(json));
          cart = json;
        }
      }
      cartTotal = cartCount(cart);
      _cartLabel = cartLabel(cartTotal);
      let $cart = $("#cart");
      $cart.find(".badge").text(cartTotal);
      $cart.attr("aria-live", "polite");
      $cart.attr("aria-label", _cartLabel);
    }

    function removeItemFromModal(e) {
      e.preventDefault();
      var $btn = $(e.target);
      var $form = $btn.parent();
      $.ajax({
        type: "POST",
        url: $form.attr("action"),
        data: $form.serialize(),
      }).done(function (html) {
        loadCartModal();
        getCartQty();
      });
    }

    function loadCartModal(data = {}, cb) {
      var $modalBody = $("#cartModal").find(".modal-body");
      $.ajax({
        url: "/shop/cart/drawer",
        data: data,
        cache: false,
      }).done(function (html) {
        $modalBody.empty();
        $modalBody.html(html.trim());
        // $modalBody.find(".remove-item").on("click", function (e) {
        //   removeItemFromModal(e);
        // });
        if (typeof cb === "function") cb();
      });
    }

    window.loadCartModal = loadCartModal;

    // console.log("page load cart qty");
    // getCartQty();

    let $cartBtns = $(".btn-cart, .btn-cart-cylindo"),
      $favoriteBtns = $(".btn-favorite"),
      $dynamicComponents = $("[data-load][data-target]"),
      $dynamicContents = $("[data-content-id][data-content-variants]");

    //Initial page cart load
    $.ajax({
      url: "/shop/cart.json",
      cache: false,
    }).done(function (res) {
      // We received the customer list object
      if (res.id && res.items.length) {
        $(res.items).each(function () {
          var item = this,
            $btn = $(
              `.btn-cart[data-product-id='${item.target_id}'], .btn-cart-cylindo[data-product-id='${item.target_id}']`
            );

          if ($btn.length) {
            if (item.options.variant_id) {
              if ($btn.attr('data-variant-id') !== item.options.variant_id) {
                return $btn.text($btn.data("update-text"));
              }
            }

            if (item.options.extra_id) {
              if ($btn.attr('data-extra-id') !== item.options.extra_id) {
                return $btn.text($btn.data("update-text"));
              }
            }

            $btn.trigger("added.gw.cart", [res]);
          }
        });
      }
      getCartQty(res);
    });

    if ($favoriteBtns.length > 0) {
      $.ajax({
        url: "/account/favorites.json",
      }).done(function (res) {
        // We received the customer list object
        if (res.id && res.items.length) {
          $(res.items).each(function () {
            var item = this,
              $btn = $(
                ".btn-favorite[data-target-type='" +
                  item.target_type +
                  "'][data-target-id='" +
                  item.target_id +
                  "']"
              );

            if ($btn.length) {
              $btn.trigger("added.gw.favorites");
            }
          });
        }
      });
    }

    if ($dynamicComponents.length > 0) {
      $dynamicComponents.each(function () {
        var $el = $(this),
          target = $el.data("target");

        if (target) {
          $el.trigger("loading.gw.dynamic");

          $.ajax({
            url: target,
            type: "GET",
          }).done(function (html) {
            if (html.trim().length <= 0) $el.remove();
            else $el.html(html);

            $el.trigger("loaded.gw.dynamic");
          });
        }
      });
    }

    if ($dynamicContents.length > 0) {
      $(document).on("ga_payload_consumed.gw.eventTracker", function (e) {
        $dynamicContents.each(function () {
          var $content = $(this);

          if (
            $content.data("contentId") &&
            $content.data("contentVariants") &&
            !$content.prop("loaded")
          ) {
            $content.trigger("loading.gw.dynamic");

            $.ajax({
              url: "/inline/content/" + $content.data("contentId"),
              data: {
                contentVariants: $content.data("contentVariants"),
              },
              type: "GET",
            }).done(function (html) {
              if (html.trim().length <= 0) $content.remove();
              else {
                $(html).each(function () {
                  var $newContent = $($(this).find(".content")[0]);
                  $newContent && $content.append($newContent.children());
                });
              }

              // We only want to load the content on the first GA tracking event (pageview)
              $content.prop("loaded", true);
              $content.trigger("loaded.gw.dynamic");
            });
          }
        });
      });
    }

    $(".product-offer, .item-detail-product-offer")
      .on("click", ".extras[data-target] .extra", function (e) {
        e.preventDefault();
        var $currentExtra = $(e.currentTarget),
          $extrasChooser = $currentExtra.parents(".extras"),
          $cartBtn = $($extrasChooser.data("target")),
          extraId = $currentExtra.data("extraId");

        $extrasChooser.find(".extra").removeClass("selected");
        $currentExtra.addClass("selected");

        if (extraId && extraId > 0) {
          var href = $cartBtn.attr("href"),
            regexp = /([\&\?]extra_id=)\d+/;
          if (href.match(regexp)) href = href.replace(regexp, "$1" + extraId);
          else href += "&extra_id=" + extraId;

          $cartBtn.attr("href", href);
        }
      })
      .on(
        "click",
        ".product-select-extras .dropdown-menu > li > .option",
        function (e) {
          e.preventDefault();
          var $option = $(e.currentTarget),
            $select = $option.parents(".product-select"),
            $cartBtn = $($select.data("target")),
            extraId = $option.data("extraId");

          $option
            .parents(".dropdown-menu")
            .find(".option.selected")
            .removeClass("selected");
          $option.addClass("selected");

          $option
            .parents(".dropdown")
            .find("> .option > .img")
            .html($option.find(".img").html());
          $option
            .parents(".dropdown")
            .find("> .option > .name")
            .html($option.find(".name").html());

          if (extraId && extraId > 0) {
            var href = $cartBtn.attr("href"),
              regexp = /([\&\?]extra_id=)\d+/;
            if (href.match(regexp)) href = href.replace(regexp, "$1" + extraId);
            else href += "&extra_id=" + extraId;

            $cartBtn.attr("href", href);
            $(e.delegateTarget).addClass("has-extra");
          } else {
            $(e.delegateTarget).removeClass("has-extra");
          }
        }
      );

    $("body")
      .on("mouseenter", ".content-product-image", function () {
        var $altImg = $(this).find(".alt img");

        if ($altImg.data("fullSrc") && !$altImg.data("fullLoad"))
          $altImg.attr("src", $altImg.data("fullSrc")).data("fullLoad", true);
      })
      .on("click", ".btn-cart, .btn-cart-cylindo", function (e) {
        var $btn = $(this),
          productId = $btn.data("productId"),
          productName = $btn.data("productName"),
          productCategory = $btn.data("productCategory"),
          productStocked = $btn.data("stocked"),
          productPrice = $btn.data("productPrice"),
          productSellingPrice = $btn.data("productSellingPrice"),
          productDiscount = $btn.data("productDiscount");

        if ($btn.hasClass("btn-cart-cylindo")) {
          if (!productStocked) return;
        }

        if ($btn.hasClass("btn-gw1")) return true;
        else {
          e.preventDefault();
          $btn.button("loading").text($btn.data("loading-text"));

          $.ajax({
            url: $btn.attr("href"),
            dataType: "json",
          }).done(function (response) {
            $btn.trigger("added.gw.cart");

            if (productId && productName) {
              // gtag("event", "add_to_cart", {
              //   currency: "USD",
              //   value: parseFloat(productSellingPrice)
              //     ? parseFloat(productSellingPrice)
              //     : parseFloat(productPrice),
              //   items: [
              //     {
              //       id: productId,
              //       name: productName,
              //       category: productCategory ? productCategory : null,
              //       currency: "USD",
              //       price: parseFloat(productSellingPrice)
              //         ? parseFloat(productSellingPrice)
              //         : parseFloat(productPrice),
              //       quantity: 1,
              //     },
              //   ],
              // });
              gtag("event", "add_to_cart", {
                currency: "USD",
                value: parseFloat(productSellingPrice)
                  ? parseFloat(productSellingPrice)
                  : parseFloat(productPrice),
                items: [
                  {
                    item_id: productId,
                    item_name: productName,
                    item_category: productCategory ? productCategory : null,
                    currency: "USD",
                    price: parseFloat(productSellingPrice)
                      ? parseFloat(productSellingPrice)
                      : parseFloat(productPrice),
                    discount: parseFloat(productDiscount),
                    quantity: 1,
                  },
                ],
              });
            }

            // Klaviyo tracking
            if (
              _learnq &&
              response.klaviyoRequests &&
              response.klaviyoRequests.length > 0
            ) {
              for (var i = 0; i < response.klaviyoRequests.length; i++) {
                _learnq.push(response.klaviyoRequests[i]);
              }
            }

            if (
              typeof sd.track === "function" &&
              response.simonSignalRequests &&
              Object.keys(response.simonSignalRequests).length > 0
            ) {
              sd.track(
                "add_to_cart",
                Object.assign(response.simonSignalRequests, { quantity: 1 })
              );
            }

            // Pinterest tracking
            typeof pintrk != "undefined" &&
              pintrk("track", "addtocart", {
                line_items: [
                  {
                    product_id: productId,
                    product_name: productName,
                    product_quantity: 1,
                  },
                ],
              });
          });
        }
      })
      .on("click", ".btn-favorite", function (e) {
        var $btn = $(this),
          targetType = $btn.data("targetType"),
          targetId = $btn.data("targetId"),
          itemName = $btn.data("itemName");

        if ($btn.attr("href") != "#") return true;
        else {
          e.preventDefault();

          var requestUrl = "/account/favorites/item",
            requestData = {
              target_type: targetType,
              target_id: targetId,
            };

          $.ajax({
            url: requestUrl,
            type: "POST",
            dataType: "json",
            data: requestData,
          }).done(function (response) {
            if (response.status == "success") {
              $btn.trigger("added.gw.favorites");

              if (itemName) {
                gtag("event", "Add", {
                  event_category: "Favorites",
                  event_label: targetType + ": " + itemName,
                });
              }
            } else if (response.status == "no-auth") {
              location.href = "/account/sign-in?done=" + location.pathname;
            }
          });
        }
      })
      .on(
        "click",
        "[data-toggle='modal'][data-target^='#finance-modal-']",
        function (e) {
          var $toggle = $(e.currentTarget),
            disclosureType = $toggle.data("target").substr(15);

          gtag("event", "View", {
            event_category: "Finance",
            event_label: "Disclosure: " + disclosureType,
          });
        }
      )
      .on("click", "[data-target='finance-app'][data-source]", function (e) {
        var $link = $(e.currentTarget);

        gtag("event", "click", {
          event_category: "Finance",
          event_label: "Link to Application from: " + $link.data("source"),
        });
      })
      .on("cylindo.gw.update", ".btn-cart-cylindo", function (e) {
        var target = $(e.currentTarget);
        getCustomCartItems(target);
      })
      .on("drawer.gw.cart", ".btn-cart, #cart", function (e) {
        var $target = $(e.currentTarget);
        var addedToCart = false;
        if ($target.hasClass("btn-cart")) {
          addedToCart = true;
        }
        loadCartModal(
          {
            added_to_cart: addedToCart,
          },
          function () {
            $("#cartModal").modal("show");
            var $drawerAlert = $("#cart-drawer-alert");
            if ($drawerAlert) {
              setTimeout(function () {
                $drawerAlert.fadeOut();
              }, 3000);
            }
          }
        );
        // $.ajax({
        //   url: "/shop/cart/drawer",
        //   data: { added_to_cart: addedToCart },
        //   cache: false,
        // }).done(function (html) {
        //   // var $cartModal = $("#cartModal");
        //   // var $modalBody = $cartModal.find(".modal-body");
        //   // $modalBody.empty();
        //   // $modalBody.html(html.trim());
        // });
      })
      .on("added.gw.cart", ".btn-cart, .btn-cart-cylindo", function (e, res) {
        var $target = $(e.currentTarget);
        if ($target.hasClass("btn-cart-cylindo")) {
          getCustomCartItems($target);
        } else {
          $(
            `.btn-cart[data-product-id=${e.currentTarget.dataset.productId}]`
          ).each(function () {
            $(this)
              .button("complete")
              .text($(e.currentTarget).data("complete-text"))
              .prepend("&nbsp;")
              .prepend($('<span class="fas fa-check"></span>'))
              .removeClass("btn-gw2")
              .addClass("btn-gw1")
              .attr("href", "/shop/cart")
              .parents(".actions")
              .find(".extras")
              .remove();
          });
          if (!res) {
            $target.trigger("drawer.gw.cart");
            $.ajax({
              url: "/shop/cart.json",
              methods: "GET",
              dataType: "json",
              cache: false,
            }).done(function (res) {
              getCartQty(res);
            });
          } else {
            getCartQty(res);
          }
        }
      })
      .on("added.gw.favorites", ".btn-favorite", function (e) {
        $(e.currentTarget)
          .empty()
          .append($('<span class="fas fa-heart"></span>'))
          .attr("href", "/account/favorites");
      })
      .on("slide.bs.carousel", ".carousel[data-wrap=0]", function (e) {
        var $slide = $(e.relatedTarget);

        if ($slide.prevAll().length == 0)
          $slide.parents(".carousel").addClass("first");
        else $slide.parents(".carousel").removeClass("first");

        if ($slide.nextAll().length == 0)
          $slide.parents(".carousel").addClass("last");
        else $slide.parents(".carousel").removeClass("last");
      });

    // initWaypoints();

    $.ajax({
      url: "/inline/header-location",
      cache: false,
      dataType: "html",
      type: "GET",
    }).done(function (html) {
      $(".navbar .your-store").html(html);
    });

    var $sidebar = $(".sidebar");
    /*
                                setTimeout(function(){
                                    $sidebar.affix({
                                        offset: {
                                            top: function() {
                                                var a = $sidebar.offset().top
                                                    , b = parseInt($sidebar.css("margin-top"), 10);
                        
                                                return this.top = a - b - 20;
                                            }
                                            , bottom: function() {
                                                return this.bottom = $("body>footer").outerHeight(!0)
                                            }
                                        }
                                    })
                        
                                    if ($sidebar.height() > $sidebar.parent().next().height()) {
                                        $sidebar.parent().next().height($sidebar.height() + 200);
                                    }
                                },500);*/

    $("#content")
      .on("click", ".sidebar .filter-sets .heading", function (e) {
        e.preventDefault();

        var $heading = $(this),
          $filters = $heading.siblings("ul.filters");

        if ($filters.hasClass("filters-closed")) {
          $filters.removeClass("filters-closed").addClass("filters-open");
          $heading
            .find("span.far")
            .removeClass("fa-caret-square-down")
            .addClass("fa-caret-square-up");
        } else {
          $filters.removeClass("filters-open").addClass("filters-closed");
          $heading
            .find("span.far")
            .removeClass("fa-caret-square-up")
            .addClass("fa-caret-square-down");
        }
      })
      .on("click", ".sidebar .sidebar-heading", function (e) {
        e.preventDefault();

        var $heading = $(this),
          $next = $heading.next();

        if ($next.is(":visible")) {
          $next.hide();
        } else {
          $next.show();
        }
      });

    // Show the contest/email alert overlay
    var overlay = $(".contest-modal");
    if (overlay.length == 1) {
      overlay = overlay[0];
      var overlayId = $(overlay).attr("id");
      if (
        !readCookie("disable_" + overlayId) &&
        !readCookie("entered_" + overlayId)
      ) {
        var tModal = window.setTimeout(
          '$("#' + overlayId + '").modal("show")',
          1500
        );
        createCookie("disable_" + overlayId, true, 1);
      }
    }

    // Handle delivery tracker form submissions
    $(".content-delivery-tracker form, form.form-delivery-tracker").on(
      "submit",
      function (e) {
        e.preventDefault();

        var $form = $(this),
          phone = $form.find("input[name=phone_number]").val();

        if (phone) {
          gtag("event", "Submit Phone", {
            event_category: "Track Delivery",
            event_label: phone,
          });

          window.open(
              "https://gardnerwhite.dispatchtrack.com/track_order/1e7db62/" + phone,
              "_blank"
          );
        }
      }
    );

    // Handle delivery check form submissions
    $("#content").on(
      "submit",
      ".sidebar-section-delivery-check form, form.cart-delivery-check",
      function (e) {
        e.preventDefault();

        var $form = $(this),
          zip = $form.find("input[name=zip]").val();

        $form.find(".modal").remove();

        gtag("event", "submit", {
          event_category: "Delivery Check",
          event_label: "Submit Zip - " + zip,
        });

        $.ajax({
          url: "/inline/delivery-check",
          data: $form.serializeArray(),
          dataType: "html",
        }).done(function (html) {
          var $modal = $(html).modal();
          $form.append($modal);
          $modal.modal("show");
        });
      }
    );

    $("#promoBadge")
      .on("click", "a", function () {
        createCookie("disable_promo_badge", true, 1); // Expires in 1 day
      })
      .on("click", ".cclose", function (e) {
        $(e.delegateTarget).fadeOut();
        return false;
      });

    var $sameDayDeliveryCountdown = $("#sameDayDeliveryCountdown");
    if ($sameDayDeliveryCountdown.length) {
      var updateSameDayDeliveryCountdown = function () {
          $.ajax({
            url: "/inline/same-day-delivery-countdown",
          }).done(function (data) {
            $("#sameDayDeliveryCountdown").html(data);
          });
        },
        tSameDayDeliveryCountdown = window.setInterval(
          updateSameDayDeliveryCountdown,
          60 * 1000
        );

      updateSameDayDeliveryCountdown();
    }

    var $blackFridayCouponModal = $("#blackFridayCouponModal"),
      blackFridayCookieName =
        "disable_black_friday_coupon_modal" +
        $blackFridayCouponModal.data("uniqueId");
    if (
      $blackFridayCouponModal.hasClass("modal") &&
      !readCookie(blackFridayCookieName)
    ) {
      var t = window.setTimeout(
        "$('#blackFridayCouponModal').modal('show')",
        1500
      );
      createCookie(blackFridayCookieName, true, 1 / 24); // Expires in 1 hour
    }

    var $christmasCountdownBadge = $("#christmasCountdownBadge"),
      christmasCountdownName =
        "disable_christmas_countdown_badge" +
        $christmasCountdownBadge.data("uniqueId");
    $christmasCountdownBadge
      .on("click", "a", function () {
        createCookie(christmasCountdownName, true, 1); // Expires in 1 day
      })
      .on("click", ".cclose", function () {
        $christmasCountdownBadge.fadeOut();
        return false;
      });

    var $vipModal = $("#vipModal"),
      $vipForm = $vipModal.find("form"),
      vipCookieName = "disable_vip_modal" + $vipModal.data("uniqueId");
    if ($vipModal.hasClass("modal") && !readCookie(vipCookieName)) {
      var t = window.setTimeout("$('#vipModal').modal('show')", 1500);
      createCookie(vipCookieName, true, 1 / 6); // Expires in 4 hours
    }

    $vipForm.on("submit", function (e) {
      e.preventDefault();

      $vipForm.find(".alert").remove();

      $.ajax({
        url: $vipForm.attr("action"),
        method: "POST",
        data: $vipForm.serializeArray(),
        dataType: "json",
      }).done(function (response) {
        if (response.email && response.insert && response.subscribe) {
          gtag("event", "Subscribe", {
            event_category: "Email",
            event_label: "VIP Promo",
          });
          $vipModal.find(".before, .disclaimer").fadeOut(400, function () {
            $vipModal.find(".after").fadeIn(600);
          });
        } else {
          $vipForm.prepend(
            $(
              '<div class="alert alert-danger">There was a problem saving your information. Please review your information and try submitting the form again.</div>'
            )
          );
        }
      });
    });

    if (!readCookie("hide-promo-badge")) {
      $("#promo-badge")
        .show()
        .on("click", "a.close", function (e) {
          e.preventDefault();

          $(e.delegateTarget).hide();

          createCookie("hide-promo-badge", true, 1);
        });
    }

    var $contestModal = $("#contestModal"),
      $contestForm = $contestModal.find("form"),
      contestCookieName =
        "disable_contest_modal_" + $contestModal.data("contestId");
    if (
      $contestModal.hasClass("modal") &&
      !readCookie(contestCookieName) &&
      !readCookie("entered_contest_" + $contestModal.data("contestId"))
    ) {
      var t = window.setTimeout("$('#contestModal').modal('show')", 1500);
      createCookie(contestCookieName, true, 1 / 6); // Expires in 4 hours
    }

    $contestForm.on("submit", function (e) {
      e.preventDefault();

      $contestForm.find(".alert").remove();

      $.ajax({
        url: $contestForm.attr("action"),
        method: "POST",
        data: $contestForm.serializeArray(),
        dataType: "json",
      }).done(function (response) {
        if (response.email && response.insert && response.subscribe) {
          gtag("event", "Subscribe", {
            event_category: "Email",
            event_label: "Contest: " + $contestModal.data("contestId"),
          });
          $contestModal.find(".before, .disclaimer").fadeOut(400, function () {
            createCookie(
              "entered_contest_" + $contestModal.data("contestId"),
              true,
              1
            );
            $contestModal.find(".after").fadeIn(600);
          });
        } else {
          $contestForm.prepend(
            $(
              '<div class="alert alert-danger">There was a problem saving your information. Please review your information and try submitting the form again.</div>'
            )
          );
        }
      });
    });

    var $promoModal = $("#promoModal"),
      $promoForm = $promoModal.find("form"),
      promoCookieName = "disable_promo_modal" + $promoModal.data("uniqueId");
    if (
      $promoModal.hasClass("modal") &&
      !readCookie(promoCookieName) &&
      !readCookie("entered_promo_" + $promoModal.data("uniqueId"))
    ) {
      var t = window.setTimeout("$('#promoModal').modal('show')", 1500);
      createCookie(promoCookieName, true, 1 / 6); // Expires in 4 hours
    }

    var $productPromoModal = $("#productPromoModal"),
      $productPromoLinks = $(".content-product a.content-product-promo");

    $productPromoLinks.on("click", function (e) {
      e.preventDefault();

      var $link = $(e.currentTarget),
        $modalBody = $productPromoModal.find(".modal-body").empty();

      $modalBody.load($link.attr("href"));

      $productPromoModal.modal("show");
    });

    var $emailSignupModal = $("#emailSignupModal"),
      $emailSignupForm = $emailSignupModal.find("form");
    if ($emailSignupModal && $emailSignupModal.hasClass("modal")) {
      var t = window.setTimeout("$('#emailSignupModal').modal('show')", 1500);
    }

    $emailSignupForm.on("submit", function (e) {
      e.preventDefault();

      $emailSignupForm.find(".btn-primary").button("loading");
      $emailSignupForm.find(".alert").remove();

      $.ajax({
        url: $emailSignupForm.attr("action"),
        method: "POST",
        data: $emailSignupForm.serializeArray(),
        dataType: "json",
      }).always(function (response) {
        if (response.email && response.insert && response.subscribe) {
          gtag("event", "Subscribe", {
            event_category: "Email",
            event_label: "Contest: [Evergreen] $1000 Shopping Spree",
          });
          $emailSignupModal
            .find(".before, .disclaimer")
            .fadeOut(400, function () {
              $emailSignupModal.find(".after").fadeIn(600);
            });
        } else {
          $emailSignupForm.find(".btn-primary").button("reset");
          $emailSignupForm.prepend(
            $(
              '<div class="alert alert-danger">There was a problem saving your information. Please review your information and try submitting the form again.</div>'
            )
          );
        }
      });
    });

    $emailSignupModal.on("click", ".btn-rules", function (e) {
      e.preventDefault();
      $emailSignupModal.find(".rules").toggle();
    });

    $("form.form-sort-menu").on("change", function (e) {
      gtag("event", "Change", {
        event_category: "Sort Listings",
        event_label: $(e.currentTarget).find("select option:selected").text(),
      });
      $(e.delegateTarget).trigger("submit");
    });

    // Open links to Publitas in a new tab
    //$('a[href$="/ad"]').attr('target','_blank');

    $(window).on("resize scroll", function (e) {
      var $window = $(e.currentTarget),
        viewportTop = $window.scrollTop(),
        viewportBtm = $window.height() + viewportTop;

      $("[data-viewload]").each(function () {
        var $el = $(this),
          $target = $el.data("target") ? $($el.data("target")) : $el,
          top = $target.offset().top,
          btm = $target.height() + top;

        if ($target.is(":visible") && btm > viewportTop && top < viewportBtm) {
          if ($target.is("img") && $el.data("src"))
            $el.attr("src", $el.data("src"));
          else if ($el.data("url")) {
            $el.trigger("loading.gw.dynamic");

            $.ajax({
              url: $el.data("url"),
              type: "GET",
            }).done(function (html) {
              if (html.trim().length <= 0) $el.remove();
              else $el.html(html);

              $el.trigger("loaded.gw.dynamic");
            });
          }
          $el
            .removeAttr("data-src")
            .removeAttr("data-viewload")
            .addClass("seen");
        }
      });
    });

    $("#content:not(#hero)").on(
      "slid.bs.carousel",
      ".content-grid-slider, .content-slider",
      function (e) {
        $(window).trigger("scroll");
      }
    );
    $(window).trigger("scroll");

    if (typeof $.fn.bcSwipe !== "undefined") {
      $(".carousel").bcSwipe({ threshold: 50 });
    }
  });
})(jQuery);

function createCookie(name, value, days) {
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    var expires = "; expires=" + date.toGMTString();
  } else var expires = "";
  document.cookie = name + "=" + value + expires + "; path=/";
}

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

function eraseCookie(name) {
  createCookie(name, "", -1);
}
