import Alpine from "alpinejs";
import focus from "@alpinejs/focus";
import mask from "@alpinejs/mask";
import intersect from "@alpinejs/intersect";
import BiggerPicture from "bigger-picture";

window.BiggerPicture = BiggerPicture;
window.Alpine = Alpine;
Alpine.plugin(mask);
Alpine.plugin(intersect);
Alpine.plugin(focus);

// const newArrivals = document.querySelector("#new-arrivals");
//
// if (newArrivals) {
//   const url = newArrivals.dataset.url;
//   const autoPlay = Boolean(newArrivals.dataset.autoplay);
//   const gaEventCategory = newArrivals.dataset.gaEventCategory;
//
//   // // TODO: this is svelte version
//   new GWProductCarousel({
//     target: newArrivals,
//     props: {
//       url,
//       autoPlay,
//       gaEventCategory,
//     },
//   });
// }
