import "./jQuery.js";
import "bootstrap";
import "slick-carousel";
import "flickity";
import "flickity-as-nav-for";
import "flickity-fade";
import "./hoverIntent.js";
import "jquery-ui";
import "jquery-ui-slider/jquery-ui.min.js";
import "jquery-ui-slider/jquery-ui.min.css";
import "./searchResults.js";
import "./contents.js";
import "./shoptelligence.js";

import "./theme.js";
import "./bootstrap.js";
import "../scss/main.scss";
import Splide from "@splidejs/splide";
import "../scss/theme/vendors/_splide.scss";
import "@splidejs/splide/css";
// allows for axios on homepage
import axios from "axios";

// require("jquery-ui");
// require("jquery-ui-slider/jquery-ui");

window.$ = window.jQuery = $;

window.axios = axios;
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

window.Splide = Splide;

window.Alpine.start()

// Navigation

// import './plugins/theme-plugin'

// eslint-disable-next-line no-unused-vars
var lpEngagementShown, lpEngagementId;
