(function ($) {
  // SEARCH RESULTS CLASS DEFINITION
  // ============================

  var SearchResults = function (element) {
    this.$element = $(element);

    this.options = this.$element.data("options")
      ? this.$element.data("options")
      : [];

    if (history.state && history.state.hash)
      var hash = decodeURIComponent(history.state.hash);
    if (hash && (hash.indexOf("/") === 0 || hash.indexOf("|") === 0))
      this.refresh({ hash: hash });
    else this.initialize();

    this.$element.on("click", "a", function (e) {
      var $link = $(e.currentTarget);

      if ($link.data("refresh") == "searchResults") {
        e.preventDefault();
        $(e.delegateTarget)
          .data("gw.searchResults")
          .refresh($(e.currentTarget).data());
      }
    });
  };

  SearchResults.prototype.initialize = function () {
    this.initializeFilters()
      .initializeSelections()
      .initializeBreadcrumbs()
      .initializeSortMenu();
    return this;
  };

  SearchResults.prototype.initializeFilters = function () {
    var $el;
    var $filterSets = this.$element.find(".sidebar .filter-sets"),
      filters = $filterSets.data("filters"),
      filterNames = [];

    if (this.options.filters) filters = this.options.filters.concat(filters);

    if (filters) {
      for (var i = 0; i < filters.length; i++) {
        var filter = filters[i],
          $filterSet = $('<div class="filter-set"></div>');

        if (filterNames.indexOf(filter.name) !== -1) continue;
        if (filter.name)
          $filterSet.append($('<h4 class="heading"></h4>').text(filter.name));

        switch (filter.valueType) {
          case "list":
            $el = this.createFilterList(filter.values, filter.type != "links");
            break;

          case "range":
            $el = this.createFilterRangeSlider(filter.values);
            break;
        }

        if ($el) {
          $filterSet.append($el);
          $filterSets.append(
            $(
              '<div class="' + $filterSets.data("gridClass") + '"></div>'
            ).append($filterSet)
          );
        }

        filterNames.push(filter.name);
      }
    }

    return this;
  };

  SearchResults.prototype.createFilterList = function (values, isMulti) {
    var $list = $('<ul class="nav filters flex-column"></ul>');

    if (values instanceof Array) {
      for (var i = 0; i < values.length; i++) {
        var value = values[i],
          $link = $("<a></a>");

        if (value.linkData) $link.data(value.linkData);
        if (isMulti)
          $link
            .append(
              $(
                '<span class="' +
                  (value.selected ? "fas" : "far") +
                  ' fa-square"></span>'
              )
            )
            .append("&nbsp;");
        if (value.href) $link.attr("href", value.href);
        $link.append(value.label);
        if (value.productCount)
          $link
            .append("&nbsp;")
            .append($('<span class="small"></span>').text(value.productCount));

        $list.append($("<li></li>").append($link));
      }
    }

    return $list;
  };

  SearchResults.prototype.createFilterRangeSlider = function (params) {
    var $searchResults = this.$element,
      $range = $('<div class="range"></div>'),
      $slider = $('<div class="range-slider"></div>');

    $range.data(params);

    if (
      params.max - params.min <= params.step ||
      params.values[1] - params.values[0] <= params.step
    )
      return false;

    $range.updateText = function (rangeVals) {
      var format;

      switch (params.name) {
        case "Price":
          format = "${0} - ${1}";
          break;

        case "Depth":
        case "Width":
        case "Height":
          format = '{0}" - {1}"';
          break;
      }

      $range.find(".text").text(
        format.replace(/{(\d+)}/g, function (match, num) {
          return typeof rangeVals[num] != "undefined" ? rangeVals[num] : match;
        })
      );
    };

    $slider.slider({
      range: true,
      min: params.min,
      max: params.max,
      step: params.step,
      values: params.values,
      slide: function (e, ui) {
        $range.updateText(ui.values);
      },
      change: function (e, ui) {
        var oldPathParts = $searchResults.data("currentPath").split("/"),
          newPathParts = [],
          vals = [
            Number(ui.values[0].toFixed(1)),
            Number(ui.values[1].toFixed(1)),
            Number(params.min.toFixed(1)),
            Number(params.max.toFixed(1)),
          ],
          data = {
            action: "selectAttribute",
            args: params.name + " = '" + vals.join("@") + "'",
          };

        for (var i = 0; i < oldPathParts.length; i++) {
          if (oldPathParts[i].indexOf(params.name + ":") != 0) {
            // begins with
            newPathParts.push(oldPathParts[i]);
          }
        }

        data.path = newPathParts.join("/");
        $searchResults.data("gw.searchResults").refresh(data);
      },
    });

    $range.append($slider);
    $range.append($('<div class="text"></div>'));
    $range.updateText(params.values);

    return $range;
  };

  SearchResults.prototype.initializeBreadcrumbs = function () {
    var $breadcrumbs = this.$element.find("ol.breadcrumb"),
      navPath = $breadcrumbs.data("navPath");

    if (!this.options.hideBreadcrumbs && navPath) {
      for (var i = 0; i < navPath.length; i++) {
        var navPathItem = navPath[i],
          $listItem = $("<li>");

        if (navPathItem.linkData.args == navPathItem.linkData.path) {
          $listItem.addClass("active").text(navPathItem.label);
        } else {
          var $link = $("<a>")
            .text(navPathItem.label)
            .data(navPathItem.linkData);
          $listItem.append($link);
        }

        $breadcrumbs.append($listItem);
      }
    } else {
      $breadcrumbs.remove();
    }

    return this;
  };

  SearchResults.prototype.initializeSelections = function () {
    var $searchResults = this.$element,
      $selections = $searchResults.find("div.selections"),
      navPath = $selections.data("navPath");

    if (!this.options.hideSelections && navPath) {
      for (var i = 0; i < navPath.length; i++) {
        var pathRE = new RegExp(
            "^" +
              $searchResults
                .data("basePath")
                .replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&")
          ),
          navPathItem = navPath[i],
          $btn = $('<a class="btn btn-default" href="#">')
            .text(navPathItem.label)
            .data(navPathItem.linkData)
            .append('<span class="fas fa-times-circle">');

        // Don't list selections that would change the basePath
        if (navPathItem.linkData.args.match(pathRE)) $selections.append($btn);
      }

      if ($selections.find(".btn").length > 0) {
        $btn = $(
          '<a class="btn btn-default btn-clear" href="#">Clear All Filters</a>'
        ).on("click", function (e) {
          e.preventDefault();
          $searchResults
            .data("gw.searchResults")
            .refresh({ path: $searchResults.data("basePath") });
        });
        $selections.append($btn);
      }
    } else {
      $selections.remove();
    }

    return this;
  };

  SearchResults.prototype.initializeSortMenu = function () {
    var $searchResults = this.$element,
      $sortMenuBtn = $searchResults.find(".listing-actions .btn-product-sort"),
      $sortMenu = $($sortMenuBtn.data("target"));

    if (!this.options.hideSortMenu && $sortMenu.length) {
      $searchResults.data(
        "currentSort",
        $sortMenu.find(".selected").data("option")
      );

      $sortMenu.on("click", ".option > a", function (e) {
        e.preventDefault();
        $searchResults.data("currentSort", $(e.currentTarget).data("option"));
        $searchResults.data("gw.searchResults").refresh();
      });
    } else {
      $sortMenuBtn.parent().remove();
      $sortMenu.remove();
    }

    return this;
  };

  SearchResults.prototype.refresh = function (data) {
    //console.log("Data (before):",data);

    var $searchResults = this.$element,
      pushState = true;

    $searchResults.trigger("refreshing.gw.searchResults");

    if (!data) {
      var data = {
        path: $searchResults.data("currentPath"),
      };
    } else delete data.refresh;

    if (typeof data.hash !== "undefined") {
      pushState = false;

      var path = data.hash.split("|")[0],
        modifiers = data.hash.split("|")[1],
        _modifiers = {};

      if (modifiers && modifiers.length) {
        modifiers = modifiers.split(",");

        for (var i = 0; i < modifiers.length; i++) {
          var parts = modifiers[i].split(":");
          if (parts.length == 2) {
            var name = parts[0].toLowerCase(),
              val = parts[1];

            _modifiers[name] = val;
          }
        }
      }
      delete data.hash, modifiers;

      if (_modifiers.page) {
        data.action = "SelectPage";
        data.args = _modifiers.page;
      }
      if (_modifiers.sort) data.sort = _modifiers.sort;

      data.path =
        (path.match(/^\//) || !path ? $searchResults.data("basePath") : "") +
        path;
    }

    if (!data.sort && $searchResults.data("currentSort"))
      data.sort = $searchResults.data("currentSort");

    //console.log("Data (after):", data);

    if (data.action && data.args)
      gtag("event", data.action, {
        event_category: "Search Results",
        event_label: data.args,
      });

    $searchResults.addClass("searching");
    $("html, body").animate(
      {
        scrollTop: $searchResults.offset().top,
      },
      500
    );

    //console.log($searchResults.data());
    if ($searchResults.data("type")) data.type = $searchResults.data("type");

    if ($searchResults.data("theme")) data.theme = $searchResults.data("theme");

    $.ajax({
      url: "/search",
      data: data,
      dataType: "json",
    }).done(function (res) {
      if (res.status === 1 && res.path && res.html) {
        var pathRE = new RegExp(
            "^" +
              $searchResults
                .data("basePath")
                .replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&")
          ),
          hash = res.path.replace(pathRE, ""),
          uri = location.pathname + location.search,
          modifiers = [];

        if (res.sort) modifiers.push("Sort:" + res.sort);
        if (res.page && res.page > 1) modifiers.push("Page:" + res.page);
        if (modifiers.length) hash += "|" + modifiers.join(",");

        if (hash) uri += "#" + hash;

        pushState &&
          history.pushState(
            {
              refresh: "searchResults",
              hash: hash,
            },
            document.title,
            uri
          );
        $searchResults
          .html(res.html)
          .removeClass("searching")
          .data("currentPath", res.path)
          .data("currentSort", res.sortOrder);
        $searchResults.data("gw.searchResults") &&
          $searchResults.data("gw.searchResults").initialize();

        $searchResults.trigger("refreshed.gw.searchResults");
      } else if (res.html) {
        $searchResults.html(res.html).removeClass("searching");
      } else if (res.url) {
        location.href = res.url;
      }

      $(window).trigger("scroll");
      if (typeof yotpo !== "undefined") {
        yotpo && yotpo.initWidgets();
      }
    });

    return this;
  };

  // SEARCH RESULTS PLUGIN DEFINITION
  // =============================

  var old = $.fn.searchResults;

  $.fn.searchResults = function (hash) {
    return this.each(function () {
      var $this = $(this),
        data = $this.data("gw.searchResults");

      if (!data)
        $this.data("gw.searchResults", (data = new SearchResults(this)));
      if (typeof hash == "string") data.refresh({ hash: hash });
    });
  };

  $.fn.searchResults.Constructor = SearchResults;

  // SEARCH RESULTS NO CONFLICT
  // =======================

  $.fn.searchResults.noConflict = function () {
    $.fn.searchResults = old;
    return this;
  };

  // SEARCH SUGGEST CLASS DEFINITION
  // ============================

  var SearchSuggest = function (input) {
    this.$input = $(input);
    this.$form = this.$input.parents("form");
    this.$list = $('<div class="search-suggestions list-group"></div>');
    this.currentKey = null;

    this.$input.attr("autocomplete", "off");

    this.$input
      .on("keydown", function (e) {
        if (e.which == 38 || e.which == 40) {
          var dir = e.which == 38,
            $input = $(e.currentTarget),
            $list = $input.data("gw.searchSuggest").$list,
            $listItems = $list.find(".list-group-item"),
            $prevListItem = $list.find(".list-group-item.active"),
            $nextListItem,
            listItemIndex;

          $listItems.removeClass("active");

          if (!$listItems.length) return;

          if ($prevListItem.length <= 0) {
            $input.data("origVal", $input.val());
            listItemIndex = dir ? $listItems.length - 1 : 0;
          } else {
            listItemIndex = $prevListItem.prevAll().length;
            if (dir) listItemIndex--;
            else listItemIndex++;
          }

          $nextListItem = $($listItems[listItemIndex]);
          if ($nextListItem.length > 0) {
            $nextListItem.addClass("active");
            $input.val($nextListItem.text());
          } else $input.val($input.data("origVal"));

          return false;

          /*if (dir) { // UP
                                  if ($prevListItem.length <= 0) $list.find('.list-group-item').last().addClass('active');
                                  else if ($prevListItem.prev().length <= 0)	$list.find()
          
                              } else { // DOWN
                                  if ($prevListItem.length <= 0) $list.find('.list-group-item').first().addClass('active');
          
                              }*/
        }
      })
      .on("keyup", function (e) {
        if (e.which == 38 || e.which == 40) return;

        var $input = $(e.currentTarget);

        if ($input.val() == $input.data("gw.searchSuggest").currentKey) return;
        $input.data("gw.searchSuggest").currentKey = $input.val();

        clearTimeout(this.t);

        this.t = setTimeout(function () {
          $input.data("gw.searchSuggest").getSuggestions();
        }, 200);
      })
      .on("focusin", function (e) {
        var $form = $(e.currentTarget).data("gw.searchSuggest").$form;
        $form.addClass("search-suggestions-open");
      })
      .on("click", function (e) {
        e.stopPropagation();
      });

    $(document.body).on("click", function () {
      $("form.search-suggestions-open").removeClass("search-suggestions-open");
    });
  };

  SearchSuggest.prototype.getSuggestions = function (key) {
    var searchSuggest = this,
      urlParams = {
        dct: "v16gardnerwhite",
        num: 8,
        key: this.currentKey,
        sort: "weight",
        reduce: "cluster",
        match: true,
        anchor: true,
      };

    $.ajax({
      url: "https://v16gardnerwhite.easyaskondemand.com/EasyAsk/AutoComplete-3.0.0.jsp",
      dataType: "jsonp",
      data: urlParams,
    }).done(function (res) {
      searchSuggest.render(res);
    });
  };

  SearchSuggest.prototype.render = function (res) {
    var $form = this.$form,
      $list = this.$list;

    $list.empty();

    if (
      res.hasOwnProperty("suggests") &&
      Array.isArray(res.suggests) &&
      res.suggests.length > 0
    ) {
      for (var i = 0; i < res.suggests.length; i++) {
        var suggestion = res.suggests[i],
          $listItem = $('<a class="list-group-item"></a>'),
          preStr = "",
          matchStr = "",
          postStr = "";

        if (suggestion.start > 0) {
          preStr = suggestion.val.substring(0, suggestion.start);
        }
        if (suggestion.end < suggestion.val.length) {
          postStr = suggestion.val.substring(suggestion.end);
        }
        matchStr = suggestion.val.substring(suggestion.start, suggestion.end);

        $listItem
          .attr("href", "/search?q=" + suggestion.val)
          .append(preStr, "<strong>" + matchStr + "</strong>", postStr);
        $list.append($listItem);
      }

      $form.append($list);
    }
  };

  // SEARCH SUGGEST PLUGIN DEFINITION
  // =============================

  var old = $.fn.searchSuggest;

  $.fn.searchSuggest = function () {
    return this.each(function () {
      var $this = $(this),
        data = $this.data("gw.searchSuggest");

      if (!data)
        $this.data("gw.searchSuggest", (data = new SearchSuggest(this)));
    });
  };

  $.fn.searchSuggest.Constructor = SearchSuggest;

  // SEARCH SUGGEST NO CONFLICT
  // =======================

  $.fn.searchSuggest.noConflict = function () {
    $.fn.searchSuggest = old;
    return this;
  };

  $(function () {
    $(".search-suggest").each(function () {
      $(this).searchSuggest();
    });
  });

  $(function () {
    var $contentSearchResults = $(".content-search-results");

    if ($contentSearchResults.length) {
      var hash = location.hash ? location.hash.substr(1) : null;

      history.replaceState(
        {
          refresh: "searchResults",
          hash: hash,
        },
        document.title,
        null
      );
    }

    $contentSearchResults.first().searchResults();

    $('a[data-refresh="searchResults"][href^="#"]').on("click", function (e) {
      //e.preventDefault();
      var $link = $(e.currentTarget),
        $searchResults = $contentSearchResults.first();

      if ($searchResults.data("gw.searchResults"))
        $searchResults
          .data("gw.searchResults")
          .refresh({ hash: $link.attr("href").substr(1) });
    });

    $contentSearchResults
      .on("click", ".listing-actions .btn", function (e) {
        var $btn = $(e.currentTarget),
          $targetMenu = $($btn.data("target"));

        $btn.parent().siblings().find(".btn").removeClass("active");
        if ($btn.hasClass("active")) {
          $btn.removeClass("active");
          $targetMenu.removeClass("open");
        } else {
          $btn.addClass("active");
          $targetMenu.addClass("open");
        }

        $targetMenu.siblings().removeClass("open");
      })
      .on("click", "#listing-action-price-display .option > a", function (e) {
        var $btn = $(e.currentTarget),
          className = "product-prices-" + $btn.data("option"),
          btnStates = {
            premier: '<span class="fas fa-dollar-sign"></span> Monthly',
            epic: '<span class="fas fa-dollar-sign"></span> Weekly',
          };

        $("body")
          .removeClass("product-prices-premier product-prices-epic")
          .addClass(className);
        $btn
          .parents(".option")
          .addClass("selected")
          .siblings()
          .removeClass("selected");

        if (btnStates.hasOwnProperty($btn.data("option")))
          $(e.delegateTarget)
            .find(
              '.listing-actions button[data-target="#listing-action-price-display"]'
            )
            .html(btnStates[$btn.data("option")]);
        //$('#listing-action-price-display').removeClass('open');
        //$(e.delegateTarget).find('.listing-actions button[data-target="#listing-action-price-display"]').removeClass('active');

        gtag("event", "selectPaymentDisplay", {
          event_category: "Search Results",
          event_label: $btn.data("option"),
        });

        $.ajax({
          type: "PUT",
          url: "/inline/price-display/" + $btn.data("option"),
        });
      });
  });
})(jQuery);
