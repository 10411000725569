import { getCartQty } from "./helpers/useCart.js";

(function ($) {
  $(".shoptelligence")
    .on("st_select_item", "shoptelligence-cui-widget", function (e) {
      if ("originalEvent" in e && "detail" in e.originalEvent) {
        window.gtag("event", "select_item", {
          event_category: "Shoptelligence",
          event_label: e.originalEvent.detail.itemId,
        });
      }
    })
    .on("st_change_ensemble", function (e) {
      if ("originalEvent" in e && "detail" in e.originalEvent) {
        window.gtag("event", "change_ensemble", {
          event_category: "Shoptelligence",
          event_label: e.originalEvent.detail.ensemblePage,
        });
      }
    })
    .on("st_change_occasion", function (e) {
      if ("originalEvent" in e && "detail" in e.originalEvent) {
        window.gtag("event", "change_occasion", {
          event_category: "Shoptelligence",
          event_label: e.originalEvent.detail.occasionLabel,
        });
      }
    })
    .on("st_email_sent", function (e) {
      if ("originalEvent" in e && "detail" in e.originalEvent) {
        window.gtag("event", "email_sent", {
          event_category: "Shoptelligence",
          event_label: e.originalEvent.detail.email,
        });
      }
    })
    .on("st_add_to_cart st_dc_add_to_cart", function (e) {
      if ("originalEvent" in e && "detail" in e.originalEvent) {
        const eventData = e.originalEvent.detail;
        const productId = parseInt(eventData.productId, 10);
        if (!productId) return;

        $.ajax({
          url: "/shop/cart/item",
          type: "POST",
          dataType: "json",
          data: {
            product_id: productId,
          },
        }).done(function (response) {
          if (typeof response === "object") {
            if ("error" in response) {
              alert(response.error);
            } else {
              const product = response.product;
              const $modal = $("#shoptelligenceModal");

              if (product.meta.id && product.name) {
                window.gtag("event", "add_to_cart", {
                  event_category: "Shoptelligence",
                  event_label: eventData.itemId,
                });

                window.gtag("event", "add", {
                  event_category: "Cart",
                  event_label: `Product: ${product.name}`,
                });

                // $(".btn-cart, .btn-cart-cylindo").trigger("added.gw.cart");
                loadCartModal(
                  {
                    added_to_cart: true,
                  },
                  function () {
                    $("#cartModal").modal("show");
                    var $drawerAlert = $("#cart-drawer-alert");
                    if ($drawerAlert) {
                      setTimeout(function () {
                        $drawerAlert.fadeOut();
                      }, 3000);
                    }
                  }
                );
                getCartQty();
                if ($("#c-form").length) {
                  window.refreshTotals();
                  window.refreshCartItems();
                  window.refreshFinanceOffer();
                }

                // if ('image_urls' in product && Array.isArray(product.image_urls)) {
                //   // $modal.find('.product-image')
                //   //   .html(`<img src="${product.image_urls[0]}" alt="${product.name}" width="100%">`)
                //   // $modal.find('.product-info')
                //   //   .html(`
                //   //     <h3>${product.name}</h3>
                //   //     <p><strong>Modal #: </strong>${product.sku}</p>
                //   //   `)
                //   // $modal.modal('show')
                //
                //   $modal.on('hidden.bs.modal', function () {
                //     $('.btn-cart, .btn-cart-cylindo').trigger('added.gw.cart');
                //     if ($('#c-form').length) {
                //         window.refreshTotals();
                //         window.refreshCartItems();
                //         window.refreshFinanceOffer();
                //     }
                //   })
                // }
              }
            }
          }
        });
      }
    });
})(jQuery);
